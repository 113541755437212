exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aktuality-index-tsx": () => import("./../../../src/pages/aktuality/index.tsx" /* webpackChunkName: "component---src-pages-aktuality-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kontakt-dekujeme-js": () => import("./../../../src/pages/kontakt/dekujeme.js" /* webpackChunkName: "component---src-pages-kontakt-dekujeme-js" */),
  "component---src-pages-kontakt-index-js": () => import("./../../../src/pages/kontakt/index.js" /* webpackChunkName: "component---src-pages-kontakt-index-js" */),
  "component---src-pages-predzapis-index-tsx": () => import("./../../../src/pages/predzapis/index.tsx" /* webpackChunkName: "component---src-pages-predzapis-index-tsx" */),
  "component---src-pages-predzapis-prijato-tsx": () => import("./../../../src/pages/predzapis/prijato.tsx" /* webpackChunkName: "component---src-pages-predzapis-prijato-tsx" */),
  "component---src-pages-zapis-index-tsx": () => import("./../../../src/pages/zapis/index.tsx" /* webpackChunkName: "component---src-pages-zapis-index-tsx" */),
  "component---src-pages-zapis-prijato-tsx": () => import("./../../../src/pages/zapis/prijato.tsx" /* webpackChunkName: "component---src-pages-zapis-prijato-tsx" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-gallery-tsx": () => import("./../../../src/templates/gallery.tsx" /* webpackChunkName: "component---src-templates-gallery-tsx" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */)
}

